import React, { useState } from "react"
import MySelectInput from "../textInput/textInput"
import { useLang } from "hooks/useLang"
import { getQueryParam, addQueryParam } from "helpers/searchParam"
import { cities } from "./cities"
import { FilterComponentProps } from "./eventFiltration.interface"
import CustomSelect from "../selectBox/selectBox"

export default function EventFilteration<T>({
    options,
    data,
    filterFunction,
    setFilteredEvents,
}: FilterComponentProps<T>) {
    const [selectedDate, setSelectedDate] = useState(options[0].value)

    const { t } = useLang()

    const onFilter = () => {
        var events = data
        const locationValue = getQueryParam("location")
        const dateValue = getQueryParam("date")

        if (locationValue) {
            if (locationValue !== "all") {
                const filterdEventsBasedOnLocation = events?.filter(
                    // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                    (event: any) => event.address.city === locationValue
                )
                events = filterdEventsBasedOnLocation
            } else {
                if (dateValue !== "all") {
                    const filterdEventsBasedOnDate = events?.filter((item) =>
                        filterFunction(item, dateValue!)
                    )
                    events = filterdEventsBasedOnDate
                } else {
                    events = data
                }
            }
        }

        if (dateValue) {
            if (dateValue !== "all") {
                const filterdEventsBasedOnDate = events?.filter((item) =>
                    filterFunction(item, dateValue)
                )
                events = filterdEventsBasedOnDate
            } else {
                if (locationValue) {
                    if (locationValue !== "all") {
                        console.log("empty")
                        const filterdEventsBasedOnLocation = events?.filter(
                            // biome-ignore lint/suspicious/noExplicitAny: <explanation>
                            (event: any) =>
                                event?.address?.city === locationValue
                        )
                        events = filterdEventsBasedOnLocation
                    }
                } else {
                    events = data
                }
            }
        }

        return events
    }

    const onValueChange = async ({
        key,
        value,
    }: { key: string; value: string }) => {
        addQueryParam(key, value)
        const filteredEvents = onFilter()
        setFilteredEvents(filteredEvents)
    }

    return (
        <div className="relative w-full rounded-lg border-[1px] border-gray-500 border-opacity-60 px-8 py-4 text-white dark:border-white">
            <div className="flex flex-wrap items-start justify-between gap-y-5 flex-col">
                <div>
                    <div className="flex flex items-start justify-center flex-col lg:flex-row">
                        <span className="text-black dark:text-white text-[16px] ">
                            {t("whatsHappeningIn")}
                        </span>

                        <span className="">
                            <CustomSelect
                                placeholder={cities[1].label}
                                options={cities}
                                onSelect={(value) => {
                                    // e.preventDefault()
                                    onValueChange({
                                        key: "location",
                                        value: value,
                                    })
                                }}
                            />
                        </span>
                    </div>
                </div>
                <div className="flex gap-4  flex-col lg:flex-row">
                    {options.map((option) => (
                        <button
                            key={option.value}
                            className={`flex items-center space-x-2 rounded-md px-2 py-2 ${
                                selectedDate === option.value
                                    ? "text-orange-500"
                                    : "text-gray-700 dark:text-gray-100"
                            }`}
                            onClick={(e) => {
                                e.preventDefault()
                                setSelectedDate(option.value)
                                onValueChange({
                                    key: "date",
                                    value: option.value,
                                })
                            }}
                        >
                            <span>{t(option.label)}</span>
                        </button>
                    ))}
                </div>
            </div>
        </div>
    )
}
